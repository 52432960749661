<template>
  <div class="puzzle-area">
    <div class="puzzle-header">
      <input class="titleinput" type="text" v-model="puzzle.Name" />
      <span>
        <button
          class="action"
          @click="deletePuzzle()">
          <Icon icon="ci:trash-full"></Icon>
        </button>
        <button
          class="action"
          @click="saveAndReturn()">
          <Icon icon="ci:check-big"></Icon>
        </button>
        <button
          class="action"
          @click="$router.push('/puzzle/'+$route.params.puzzle_id)">
          <Icon icon="ci:close-big"></Icon>
        </button>
      </span>
    </div>
    <div class="puzzle-content">
      <div class="puzzle-left">
        <SimpleMDE v-model="puzzle.Text">
        <DropZone
          url="/upload"
          :uploadOnDrop="true">
        </DropZone>
        </SimpleMDE>
      </div>
      <div class="puzzle-right">
        <h3 class="prebox">Hints (one per line)</h3>
        <textarea id="hints"
          :value="puzzle.Hints.join('\n')"
          @input="ev => puzzle.Hints = ev.target.value.split(/\r?\n/)"></textarea>
        <h3 class="prebox">Eurekas (one per line)</h3>
        <textarea
          :value="puzzle.Eurekas.join('\n')"
          @input="ev => puzzle.Eurekas = ev.target.value.split(/\r?\n/)"></textarea>
      </div>
    </div>
    Solution: <input type="text" v-model="puzzle.Solution" />
  </div>
</template>

<script>
  import SimpleMDE from './SimpleMDE.vue';
  import { Icon } from '@iconify/vue';
  import Swal from 'sweetalert2';
  import DropZone from 'dropzone-vue';

  export default {
    name: 'PuzzleEditor',
    components: {
      SimpleMDE,
      Icon,
      DropZone,
    },
    data() {
      return {
        puzzle: {
          ID: -1,
          Name: "",
          AuthorID: -1,
          Solution: "",
          Eurekas: [],
          Hints: [],
          Text: ""
        },
      }
    },
    methods: {
      reset: function() {
        this.puzzle = {
          ID: -1,
          Name: "",
          AuthorID: -1,
          Solution: "",
          Eurekas: [],
          Hints: [],
          Text: ""
        };
      },
      loadPuzzle: function() {
        if(this.$route.name != 'edit_puzzle' || this.$route.params.puzzle_id == null) {
          return;
        }
        this.reset();
        if(this.$route.params.puzzle_id == null) {
          return;
        }
        const puzzleID = this.$route.params.puzzle_id
        fetch(`/get/full_puzzle/${puzzleID}`)
        .then(response => response.json())
        .then(puzzle => {
          if(puzzle.ID != 0) {
            console.log("Puzzle Editor");
            console.log(puzzle);
            this.puzzle = puzzle;
          }
        })
      },
      saveAndReturn: function() {
        fetch(`/change_puzzle`, {
          method: 'post',
          body: JSON.stringify(this.puzzle),
          headers: new Headers({
            'Content-Type': 'application/json',
          })
        })
        .then(response => response.text())
        .then(() => {this.$root.loadPuzzles(); this.$router.push('/puzzle/'+this.$route.params.puzzle_id)})
      },
      deletePuzzle: function() {
        Swal.fire({
          icon: 'question',
          title: 'Delete Puzzle?',
          text: `Are you sure you want to delete puzzle "${this.puzzle.Name}"?`,
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          confirmButtonColor: '#16a085',
          denyButtonColor: '#FB4D3D',
          customClass: {
            actions: 'my-actions',
            confirmButton: 'order-2',
            denyButton: 'order-1',
          }
        }).then((result) => {
          if(result.isConfirmed) {
            fetch(`/delete_puzzle/${this.puzzle.ID}`, {
              method: 'post',
            })
            .then(response => response.text())
            .then(() => {this.$root.loadPuzzles(); this.$router.push('/')})
          }
        })
      },
    },
    created() {
      this.loadPuzzle();
      this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (pre, post) => {
        this.loadPuzzle()
      })
    }
  }
</script>

<style>
  @import '~simplemde/dist/simplemde.min.css';
  h3.prebox {
    margin-top: 0px;
    margin-bottom: 1em;
  }
  .titleinput {
    font-size: 18pt;
    font-weight: bold;
  }
  textarea {
    min-width: 300px;
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
    margin-bottom: 1em;
    font-family: inherit;
  }
</style>

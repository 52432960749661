<template>
  <form @submit.prevent="$root.login()">
  <div class="login-container">
    <input type="text" placeholder="Username" id="username" required>
    <input type="password" placeholder="Password" id="password" required>
    <button type="submit">Login</button>
  </div>
</form>
</template>

<script>
export default {
  name: 'LoginView',
}
</script>

<style>
.login-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.login-container input
{
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ddd;
}

.login-container input[type="text"],
.login-container input[type="password"]
{
  margin: 5px 5px 5px 5px;
  width: 70px;
  padding: 10px;
  flex: 1 1 30%;
}

.login-container label {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
  float:left;
}
</style>

<template>
  <div class="sidenav" :class="{ collapsed: collapsed && this.$route.name != 'empty' && $root.user.ID != -1}">
    <div class="navheader">
      <h2>Bootcamp</h2>
      <button
        class="action mobile"
        @click="close">
        <Icon icon="carbon:close"></Icon>
      </button>
    </div>
    <div class="navscroll">
      <div v-if="user.ID !== -1" class="list_item"><Icon icon="ci:plus"></Icon>
        <a href="javascript:void(0)" @click="this.$root.newPuzzle()">Add new Puzzle</a>
      </div>
      <div v-for="puzzle in puzzles" :key="puzzle.ID"
        :class="{ list_item: true, active: $route.params.puzzle_id == puzzle.ID }">
        <Icon :inline="true" v-if="puzzle.AuthorID === user.ID && !puzzle.Public"
          title="Hidden from other users" icon="carbon:view-off-filled"></Icon>
        <Icon :inline="true" v-else-if="puzzle.AuthorID === user.ID && puzzle.Public"
          title="Shown to other users" icon="carbon:edit"></Icon>
        <Icon :inline="true" v-else-if="puzzle.Solved" title="Solved"
          icon="carbon:checkbox-checked"></Icon>
        <Icon :inline="true" v-else title="Not Started" icon="carbon:checkbox"></Icon>
        <a href="javascript:void(0)" @click="navigate(puzzle.ID)">{{ puzzle.Name }}</a>
      </div>
    </div> <!-- navscroll -->
    <div class="userbox">
      <div v-if="user.ID === -1" class="login-box">
        <LoginView></LoginView>
      </div>
      <div v-else>
        <LogoutView></LogoutView>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import LoginView from './LoginView.vue'
import LogoutView from './LogoutView.vue'

export default {
  name: 'SideBar',
  components: {
    Icon,
    LoginView,
    LogoutView,
  },
  methods: {
    navigate(id) {
      const params = this.$route.params;
      console.log(params.id);
      console.log(id);
      if(params.id != id) {
        this.$router.push(`/puzzle/${id}`);
      }
      this.close();
    },
    close() {
      this.collapsed = true;
    },
  },
  data() {
    return {
      collapsed: false
    }
  },
  props: ['user', 'puzzles'],
  mounted() {
    this.events.on('toggleSidebar', () => {
      this.collapsed = !this.collapsed;
    });
    console.log(this.$route.name);
    if(this.$route.name === 'empty') {
      this.collapsed = false;
    } else {
      this.collapsed = true;
    }
  }
}
</script>

<style>
h2 {
  margin: 10px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* The sidebar menu */
.navheader {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #ccc;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden;
  flex-grow: 0;
  /* width: 20vw; */
  min-width: 300px;
  transition: 500ms;
  padding: 0 0 0 10px;
  display: flex;
  flex-flow: column;
}

.navscroll {
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;

  scrollbar-color: #888 #ccc;
}
.navscroll::-webkit-scrollbar {
  background: #ccc;
  width: 10px;
}
.navscroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  border: 2px solid #ccc;
}
::-webkit-scrollbar {
  background: #ecf0f1;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  border: 2px solid #ecf0f1;
}

.mobile {
  visibility: collapse;
}

@media only screen and (max-width: 760px) {
  .sidenav {
    position: absolute;
    max-width: calc(100% - 10px);
    width: 100%;
  }
  .mobile {
    visibility: visible;
  }
  button.action {
    font-size: 40pt;
  }
  .sidenav.collapsed {
    margin-left: -100%;
  }
}

.sidenav div.list_item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 8px 0px;
}

.sidenav svg.iconify {
  margin-right: 10px;
}

/* The navigation menu links */
.sidenav a {
  padding: 2px 0px;
  text-decoration: none;
  color: #888;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #000;
}

.sidenav .active,
.sidenav .active * {
  color: #000 !important;
}

div.userbox {
  padding: 10px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: calc(100% - 20px);
}

div.login-box,
div.login-box > form {
  width: 100%;
}
</style>

<template>
  Logged in as {{ $root.user.Name }}
  <button @click="$root.logout()">Logout</button>
</template>

<script>
export default {
  name: 'LogoutView',
}
</script>

<style>
</style>

"use strict"
import * as Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue' 
import mitt from 'mitt';
import PuzzleView from './components/PuzzleView.vue'
import PuzzleEditor from './components/PuzzleEditor.vue'
import './assets/css/main.css';

const routes = [
  { path: '/', component: {template: ''}, name: 'empty' },
  { path: '/puzzle/:puzzle_id', component: PuzzleView, name: 'view_puzzle'},
  { path: '/edit/:puzzle_id', component: PuzzleEditor, name: 'edit_puzzle'},
]
const router = createRouter({
  history: createWebHistory('/'),
  routes
})

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.onresize = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const app = Vue.createApp(App)
app.use(router)
const events = mitt();
app.config.globalProperties.events = events;
app.mount('#app')



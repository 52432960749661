<template>
  <div class="puzzle-area">
    <div class="puzzle-header">
      <span class="header-title">
        <button class="action mobile" @click="events.emit('toggleSidebar');"
          style="margin-left: 0;">
          <Icon icon="carbon:menu" style="vertical-align: 0.125em;"></Icon>
        </button>
      <h2 style="display:inline;">{{ puzzle.Name }}</h2>
      </span>
      <span>
        <button class="action" @click="resetProgress()">
          <Icon icon="carbon:renew"></Icon>
        </button>
        <button
          v-if="puzzle.Author && puzzle.AuthorID == $root.user.ID"
          class="action"
          @click="$router.push('/edit/'+$route.params.puzzle_id)">
          <Icon icon="carbon:edit"></Icon>
        </button>
      </span>
    </div>
    <div class="puzzle-content">
      <div class="puzzle-left">
        <MarkdownView v-bind:markdown="puzzle.Text"></MarkdownView>
        <div class="history-box" id="history-box">
          <div class="history-flex">
            <div v-for="event in history" :key="event">
              <span :class="event.type">
                <span v-if="event.type == 'guess'">
                  <Icon :inline="true" icon="carbon:close-filled" color="#FB4D3D"></Icon>
                </span>
                <span v-else-if="event.type == 'hint'">
                  Hint: 
                </span>
                {{ event.message }}
              </span>
              <span v-if="event.type == 'eureka'">
                was a eureka!
              </span>
              <span v-if="event.type == 'solution'">
                was the solution!
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="puzzle-right">
        <h3 v-if="puzzle.Hints.length > 0">Hints</h3>
        <div class="hintlist">
          <div v-for="hint in puzzle.Hints" :key="hint">{{ hint }}</div>
        </div>
        <h3 v-if="puzzle.Eurekas.length > 0">Eurekas</h3>
        <div class="eurekalist">
          <div v-for="eureka in puzzle.Eurekas" :key="eureka">{{ eureka }}</div>
        </div>
      </div>
    </div>
    <input type="text" class="chat-box" id="chat-box"
      v-model="chatInput" v-on:keydown="textboxUpdate"
      autofocus />
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import MarkdownView from './MarkdownView.vue';
import Swal from 'sweetalert2'

export default {
  name: 'PuzzleView',
  components: { Icon, MarkdownView },
  data() {
    return {
      puzzle: {Author: {ID: -1}, Eurekas: [], Hints: []},
      hints: [],
      history: [],
      chatInput: "",
    }
  },
  methods: {
    reset: function() {
      this.puzzle = {Author: {ID: -1}, Eurekas: [], Hints: []},
      this.history = [{
        type: 'explanation',
        message: "Type !hint to receive a hint when you're stuck.",
      }];
      this.chatInput = "";
      this.initialized = false;
    },
    loadPuzzle: function() {
      this.reset()
      if(this.$route.name != 'view_puzzle' || this.$route.params.puzzle_id == null) {
        return;
      }
      const puzzleID = this.$route.params.puzzle_id
      fetch(`/get/puzzle/${puzzleID}`)
        .then(response => response.json())
        .then(puzzle => {
          if(puzzle.ID != 0) {
            console.log("Puzzle View");
            console.log(puzzle);
            this.puzzle = puzzle;
          }
          const chatbox = document.getElementById('chat-box');
          if(chatbox != null) {
            chatbox.focus();
          }
        });
    },
    guess: function(guessStr)  {
      const component = this;
      const puzzleID = this.$route.params.puzzle_id;
      fetch(`/guess/${puzzleID}`, {
        method: 'post',
        body: JSON.stringify({
          guess: guessStr,
        }),
        headers: new Headers({
          'Content-Type': 'text/plain'
        })
      })
      .then(response => response.json())
      .then(data => {
        switch(data.Event) {
          case "solved":
            component.history.push({
            type: "solution",
            message: data.Message,
          });
          Swal.fire({
            icon: 'success',
            title: 'Solved',
            text: `"${data.Message}" was the solution`,
            confirmButtonText: 'Nice!',
            confirmButtonColor: '#16a085',
            customClass: {
              actions: 'my-actions',
            }
          })
          component.$root.loadPuzzles();
          break;
          case "eureka":
            component.history.push({
              type: "eureka",
              message: guessStr,
            });
            component.puzzle.Eurekas = JSON.parse(data.Message);
            break;
          case 'hint':
            component.history.push({
            type: 'hint',
            message: data.Message,
          });
          component.puzzle.Hints.push(data.Message)
          break;
          case 'explanation':
            if(!component.initialized) {
            component.history.push({
              type: data.Event,
              message: data.Message,
            })
          }
          component.initialized = true;
          break
          default: 
            component.history.push({
            type: data.Event,
            message: data.Message,
          })
          break;
        }
      })
    },
    textboxUpdate: function(ev) {
      if(ev.key === 'Enter') {
        this.guess(this.chatInput);
        this.chatInput = "";
      }
    },
    resetProgress: function() {
      Swal.fire({
        icon: 'question',
        title: 'Reset Progress?',
        text: `Do you want to reset your progress for puzzle "${this.puzzle.Name}"?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        confirmButtonColor: '#16a085',
        denyButtonColor: '#FB4D3D',
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
          denyButton: 'order-1',
        }
      }).then((result) => {
        if(result.isConfirmed) {
          fetch(`/reset_progress/${this.puzzle.ID}`, { method: 'post', })
            .then(response => response.text())
            .then(() => {this.$root.loadPuzzles(); this.loadPuzzle()})
        }
      })
    }
  },
  updated() {
    const historyBox = document.getElementById("history-box");
    historyBox.scrollTop = historyBox.scrollHeight;
  },
  created() {
    this.loadPuzzle();
    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
        (pre, post) => {
        this.loadPuzzle()
      }
    )
  }
}
</script>

<style>
@media only screen and (max-width: 760px) {
  div.puzzle-header {
    margin: 0 -16pt;
  }
}

CodeMirror img,
div.puzzle-content img {
  max-height: 40vh;
  max-width: 100%;
}

div.puzzle-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

div.puzzle-header > span {
  white-space: nowrap;
}


div.puzzle-area {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
}

div.puzzle-left > div,
div.puzzle-area > div {
  flex: 0 1 auto;
}

div.puzzle-area > div.puzzle-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  height: 500px;
}

div.puzzle-left {
  height: calc(var(--vh, 1vh) * 100 - 130px);
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
}

div.puzzle-left > div.history-box {
  flex: 1 1 auto;
  overflow-y: scroll;
  -webkit-mask-image: linear-gradient(0deg, #000 80%, transparent 95%);
}

div.history-flex {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  bottom: 0;
  min-height: 100%;
}

div.puzzle-right {
  flex: 0 1 auto;
  max-width: 20vw;
  width: 20vw;
  margin-left: 1em;
  height: 70%;
}

input.chat-box {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 10px 0;
  padding: 10px;
}

input.chat-box:disabled {
  background-color: #ecf0f1;
}


div.hintlist,
div.eurekalist {
  margin-top: 4px;
  overflow-y: scroll;
  max-height: 40%;
}

div.history-box span.raw_socket {
  color: #888;
}

div.history-box span.guess {
  color: #666;
}

div.history-box span.eureka,
div.history-box span.solution {
  color: #16A085;
  font-weight: bold;
}

div.history-box span.hint {
  color: #3F4B3B;
  font-weight: bold;
}

div.history-box span.hint,
div.history-box span.hints_exhausted {
  color: #3F4B3B
}

div.history-box span.explanation {
  color: #888
}

span.header-title {
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-shrink: 1;
  min-width: 100px;
}
</style>


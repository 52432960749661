<template>
  <SideBar v-bind:user="user" v-bind:puzzles="puzzles"></SideBar>
  <div class="main">
    <div class="app-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'

import SideBar from './components/SideBar.vue'

export default {
  name: 'App',
  components: {
    SideBar,
  },
  data() {
    return {
      activePuzzle: {},
      puzzles: [],
      user: {
        Name: '',
        ID : -1,
      },
      tableCollapsed: true,
    }
  },
  methods: {
    authenticate: function() {
      const username = document.getElementById('username').value;
      const password = document.getElementById('password').value;
      fetch("/authenticate", {
        method: 'post',
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      })
      .then(response => response.json())
      .then(auth => {
        if(auth.Successful) {
          if(Cookies.get('cookieConsent') === 'true') {
            Cookies.set('authToken', auth.Token, {
              expires: (10 * 365),
              domain: window.location.hostname,
              sameSite: 'strict',
              secure: false
            })
          }
          if(auth.NewlyCreated) {
            Swal.fire({
              icon: 'success',
              title: 'Account Created',
              text: 'Your account "' + username + 
                '" was created.\nHave fun puzzling!',
              confirmButtonColor: '#16a085'
            })
          }
          this.post_authenticate()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Wrong Password',
            text: 'You entered the wrong password for the given username, '
            +'please try again',
            confirmButtonColor: '#16a085'
          })
        }
      })
    },
    post_authenticate: function() {
      this.getUserInfo();
      this.loadPuzzles();
    },
    isMobile: function() {
      return screen.width <= 760;
    },
    getUserInfo: function() {
      fetch('/get/userinfo')
      .then(response => response.json())
      .then(user => {if(user.Name != "") this.user = user})
    },
    loadPuzzles: function() {
      fetch('/get/puzzles')
      .then(response => response.json())
      .then(response => {
        if(!Object.prototype.hasOwnProperty.call(response, 'message')) {
          this.puzzles = response
        }
      })
    },
    logout: function() {
      this.$router.push('/')
      this.user.ID = -1
      this.user.Name = ""
      this.puzzles = [];
      Cookies.remove('authToken')
    },
    login: async function() {
      const consent = await this.cookie_warning();
      if(consent) {
        this.authenticate();
      }
    },
    cookie_warning: async function() {
      if(Cookies.get("cookieConsent") === 'true') {
        return true;
      }
      const result = await Swal.fire({
        icon: 'info',
        title: 'Cookie Consent',
        text:'Logging in requires setting a cookie. Are you okay with that?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        confirmButtonColor: '#16a085',
        denyButtonColor: '#FB4D3D',
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
          denyButton: 'order-1',
        }
      })
      if(result.isConfirmed) {
        console.log("setting cookieConsent cookie")
        Cookies.set('cookieConsent', 'true', {
          expires: (10 * 365),
          domain: window.location.hostname,
          sameSite: 'strict',
          secure: true
        })
      }
      return result.isConfirmed;
    },
    newPuzzle: function() {
      fetch(`/create_puzzle`)
      .then(response => response.json())
      .then(new_puzzle => {
        this.loadPuzzles();
        this.$router.push('/edit/' + new_puzzle.ID)
      })
    }
  },
  mounted() {
    const token = Cookies.get('authToken')
    if(token != null && token !== '') {
      this.post_authenticate();
    }
  },
}
</script>

<style>
.main {
  flex-grow: 1;
  overflow-y: auto;
}

#header {
  /* height: 3cm; */
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  max-height: 3cm;
  height: 3cm;
  background-color: #ccc;
}

#header h1 {
  margin-left: 16pt;
}

#header > div {
  margin-right: 16pt;
}

.app-content {
  padding: 0 16pt;
}

</style>

<template>
  <div v-html="markup">
  </div>
</template>

<script>
import { marked } from 'marked';
export default {
  props: ['markdown'],
  computed: {
    markup() {
      return marked.parse(this.markdown || "");
    },
  },
};
</script>
